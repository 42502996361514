<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Cancelamento e Devoluções</h4>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="canceledOrdersList"
            :fields="fieldsCancelOrder"
            bordered
            striped
            hover
            mouse-pointer
            @row-clicked="getAllProducts"
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                title="Visualizar infomação do pedido"
                @click="getAllProducts(row.item)"
              >
                <b-icon icon="eye-fill"></b-icon>
              </b-button>

              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                title="Inserir informações adicionais"
                @click="openAditionalInformationModal(row.item.id)"
              >
                <b-icon icon="info-circle"></b-icon>
              </b-button>

              <b-button
                v-if="row.item.status === 'ANALISE' || !row.item.sallerReason"
                size="sm"
                variant="primary"
                class="mr-1"
                title="Atualizar situação do pedido"
                @click="openSellerModal(row.item.id)"
              >
                <b-icon icon="reply-all-fill"></b-icon>
              </b-button>

              <b-button
                v-if="!row.item.nfeXmlLink && row.item.status === 'APROVADO'"
                size="sm"
                variant="primary"
                class="mr-1"
                title="Carregar XML"
                @click="openInserXmlModal(row.item.id)"
              >
                <b-icon icon="journal-text"></b-icon>
              </b-button>

              <b-button
                v-if="
                  !row.item.postMethod &&
                  !row.item.postCode &&
                  row.item.status === 'APROVADO' &&
                  row.item.nfeXmlLink
                "
                size="sm"
                variant="primary"
                title="Inserir método e codigo de postagem"
                @click="openMethodModal(row.item.id)"
              >
                <b-icon icon="truck"></b-icon>
              </b-button>
            </template>
          </b-table>
          <b-button
            v-if="loadMoreCanceledOrderButton"
            @click="loadMoreCanceledOrder"
            >CARREGAR MAIS</b-button
          >
        </b-card-text>

        <b-modal
          v-model="insertXmlModal"
          title="Insira o XML desse pedido"
          hide-footer
        >
          <b-form-file
            v-model="xmlFile"
            variant="dark"
            placeholder="Escolha o arquivo .xml para ser enviado"
            accept=".xml"
            no-drop
          >
          </b-form-file>
          <b-button class="mt-4" @click="uploadXml">OK</b-button>
        </b-modal>

        <b-modal
          v-model="aditionalInformationModal"
          title="Informação adicional"
          size="xl"
          hide-footer
        >
          <b-form @submit.prevent="submitAditionalInformation">
            <form-textarea
              label="Adicione aqui a observação"
              v-model="aditionalInformation"
              rows="4"
              maxRows="2"
              class="mt-2"
              :errors="errors['aditionalInformation']"
            />
            <b-button type="submit" variant="primary" block>SALVAR</b-button>
          </b-form>
        </b-modal>

        <b-modal
          v-model="methodModal"
          title="Método e código de postagem "
          size="xl"
          hide-footer
        >
          <b-form @submit.prevent="submitPostMethod">
            <b-row>
              <b-col md="6">
                <form-select
                  v-model="methodForm.postMethod"
                  :options="postMethodOptions"
                  :errors="errors['postMethod']"
                  required
                />
              </b-col>
            </b-row>
            <form-textarea
              label="Código de postagem"
              v-model="methodForm.postCode"
              rows="4"
              maxRows="2"
              class="mt-2"
              :errors="errors['postCode']"
            />
            <b-button type="submit" variant="primary" block>SALVAR</b-button>
          </b-form>
        </b-modal>

        <b-modal
          v-model="sellerModal"
          title="Responder cliente"
          size="xl"
          hide-footer
        >
          <b-form @submit.prevent="submitStatusAndSallerReason">
            <b-row>
              <b-col md="6">
                <form-select
                  v-model="sellerForm.status"
                  :options="statusOptions"
                  :errors="errors['status']"
                  required
                />
              </b-col>
            </b-row>
            <form-textarea
              v-model="sellerForm.sallerReason"
              label="Razão da loja"
              rows="4"
              maxRows="2"
              class="mt-2"
              :errors="errors['sallerReason']"
            />
            <b-button type="submit" variant="primary" block>RESPONDER</b-button>
          </b-form>
        </b-modal>

        <b-modal
          v-model="infoModal"
          title="Detalhes do Cancelamento"
          ok-only
          size="xl"
        >
          <b-row>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.orderId"
                label="N° Pedido"
                disabled
                copyable
                icon="three-dots"
              />
            </b-col>

            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.status"
                label="Situação"
                disabled
                :icon="statusIcon"
              />
            </b-col>

            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.refundMethod"
                label="Método de reembolso"
                disabled
                icon="cash-stack"
              />
            </b-col>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.type"
                label="Tipo"
                disabled
                icon="box-seam"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <FormInput
                v-model="formatLastAlterationStatusDate"
                label="Ultima alteração do status"
                disabled
                copyable
                icon="calendar2-day"
              />
            </b-col>
            <b-col>
              <FormInput
                v-model="formatedData"
                label="Data de solicitação"
                disabled
                copyable
                icon="calendar2-day"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.postMethod"
                label="Método de postagem"
                disabled
                copyable
                icon="mailbox"
              />
            </b-col>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.postCode"
                label="Código de postagem"
                disabled
                copyable
                icon="upc"
              />
            </b-col>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.nfeAccessKey"
                label="Chave de acesso"
                disabled
                copyable
                icon="key"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <FormInput
                v-model="this.orderCancelDetails.nfeXmlLink"
                label="Link de download do XML"
                disabled
                copyable
                icon="link45deg"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <form-textarea
                v-model="this.orderCancelDetails.customerReason"
                label="Motivo do cliente"
                disabled
                copyable
                icon="chat-left-text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <form-textarea
                v-model="this.orderCancelDetails.sallerReason"
                label="Motivo do vendedor"
                disabled
                copyable
                icon="chat-right-text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <form-textarea
                v-model="this.orderCancelDetails.aditionalInformation"
                label="Informações adicionais"
                disabled
                copyable
                icon="chat-square-quote"
              />
            </b-col>
          </b-row>
          <b-table
            :items="canceledOrderProductsList"
            :fields="productsField"
          ></b-table>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import { mapMutations } from "vuex";

export default {
  components: { FormInput, FormTextarea, FormSelect },
  data() {
    return {
      formatedData: "",
      orderCancelDetails: "",
      cancelOrderId: "",
      canceledOrdersList: [],
      canceledOrderProductsList: [],
      loadMoreCanceledOrderButton: true,
      aditionalInformationModal: false,
      aditionalInformation: "",
      infoModal: false,
      sellerModal: false,
      insertXmlModal: false,
      xmlFile: null,
      canceledOrderPage: 2,
      sellerForm: {
        status: "defaultMessage",
        sallerReason: "",
      },
      methodModal: false,
      methodForm: {
        postMethod: "defaultMessage",
        postCode: "",
      },

      statusOptions: [
        { id: "defaultMessage", text: "Selecione a situação do pedido" },
        { id: "RECUSADO", text: "RECUSADO" },
        { id: "APROVADO", text: "APROVADO" },
      ],
      postMethodOptions: [
        { id: "defaultMessage", text: "Selecione o método de postagem" },
        { id: "CORREIOS", text: "CORREIOS" },
        { id: "TRANSPORTADORA", text: "TRANSPORTADORA" },
        { id: "ENTREGAR_NA_LOJA", text: "ENTREGAR NA LOJA" },
      ],
      fieldsCancelOrder: [
        {
          key: "orderId",
          label: "N° Pedido",
          sortable: true,
        },
        {
          key: "order.client.name",
          label: "Nome do Cliente",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "refundMethod",
          label: "Forma de Estorno",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      productsField: [
        {
          key: "order_products.productCode",
          label: "Cod. Produto",
          sortable: true,
        },
        {
          key: "order_products.description",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "productQuantity",
          label: "Qtd Produtos",
          sortable: true,
        },
        {
          key: "order_products.price",
          label: "Preço",
          sortable: true,
        },
      ],
      errors: {},
    };
  },
  computed: {
    formatLastAlterationStatusDate() {
      return new Date(this.orderCancelDetails.statusDate).toLocaleString(
        "pt-BR"
      );
    },

    statusIcon() {
      if (this.orderCancelDetails.status === "APROVADO") {
        return "check2";
      }
      if (this.orderCancelDetails.status === "CANCELADO") {
        return "x";
      }
      return "question";
    },
  },

  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    async getAllProducts(order) {
      const { data } = await http(
        `/ecommerce/order/canceledOrdersProducts/${order.id}`
      );
      this.canceledOrderProductsList = data;

      this.infoModal = true;
      this.orderCancelDetails = order;
      this.formatedData = new Date(order.createdAt).toLocaleString("pt-BR");
    },

    async submitPostMethod() {
      try {
        if (
          this.methodForm.postMethod !== "defaultMessage" &&
          this.methodForm.postCode !== ""
        ) {
          this.errors = {};
          const { data } = await http({
            method: "put",
            url: `/ecommerce/order/methodAndCodePost/${this.cancelOrderId}`,
            data: {
              postMethod: this.methodForm.postMethod,
              postCode: this.methodForm.postCode,
            },
          });

          const index = this.canceledOrdersList.findIndex(
            (element) => element.id === data.id
          );
          this.canceledOrdersList.splice(index, 1, data);
          this.methodModal = false;
          this.setToastedSuccess({
            message: "Método atualizado com sucesso",
          });
        } else {
          this.setToastedError({
            message:
              "É necessario inserir um método e um código de postagem para atualizar as informações",
          });
        }
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async submitStatusAndSallerReason() {
      try {
        if (
          this.sellerForm.sallerReason !== "" &&
          this.sellerForm.status !== "defaultMessage"
        ) {
          this.setOverlay(true);
          this.sellerModal = false;
          this.errors = {};
          const { data } = await http({
            method: "put",
            url: `/ecommerce/order/methodAndSallerReason/${this.cancelOrderId}`,
            data: {
              status: this.sellerForm.status,
              sallerReason: this.sellerForm.sallerReason,
            },
          });

          const index = this.canceledOrdersList.findIndex(
            (element) => element.id === data.id
          );
          this.canceledOrdersList.splice(index, 1, data);

          this.setToastedSuccess({
            message: "Status atualizado com sucesso",
          });
        } else {
          this.setToastedError({
            message:
              "É necessario fornecer uma situação e razão para o pedido antes de responder o cliente",
          });
        }
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
      this.setOverlay(false);
    },

    async submitAditionalInformation() {
      this.errors = {};
      try {
        const { data } = await http({
          method: "put",
          url: `/ecommerce/order/aditionalInfo/${this.cancelOrderId}`,
          data: {
            aditionalInformation: this.aditionalInformation,
          },
        });
        const index = this.canceledOrdersList.findIndex(
          (element) => element.id === data.id
        );
        this.canceledOrdersList.splice(index, 1, data);
        this.aditionalInformationModal = false;

        this.setToastedSuccess({
          message: "Mensagem enviada com sucesso",
        });
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async uploadXml() {
      this.setOverlay(true);
      this.insertXmlModal = false;

      const formData = new FormData();
      formData.append("caceledOrderXml", this.xmlFile);
      await http.post(
        `/ecommerce/order/uploadCancelOrder/${this.cancelOrderId}`,
        formData
      );
      this.setOverlay(false);
    },

    async loadMoreCanceledOrder() {
      const { data: orders } = await http(
        `/ecommerce/order/canceledOrders?page=${this.canceledOrderPage}`
      );
      if (!orders.lenght) {
        this.loadMoreCanceledOrderButton = false;
      }
      this.canceledOrdersList.push(...orders);
      this.canceledOrderPage += 1;
    },

    async openAditionalInformationModal(id) {
      const { data } = await http(`/ecommerce/order/aditionalInfo/${id}`);
      this.aditionalInformation = data.aditionalInformation;
      this.errors = {};
      this.aditionalInformationModal = true;
      this.cancelOrderId = id;
    },

    openSellerModal(id) {
      this.sellerForm = {
        status: "defaultMessage",
        sallerReason: "",
      };
      this.errors = {};
      this.cancelOrderId = id;
      this.sellerModal = true;
    },

    openMethodModal(id) {
      this.methodForm = {
        postMethod: "defaultMessage",
        postCode: "",
      };
      this.cancelOrderId = id;
      this.errors = {};
      this.methodModal = true;
    },

    openInserXmlModal(id) {
      this.cancelOrderId = id;
      this.insertXmlModal = true;
    },
  },
  async created() {
    this.setOverlay(true);
    const { data: orders } = await http(
      `/ecommerce/order/canceledOrders?page=1`
    );
    this.canceledOrdersList = orders;
    this.setOverlay(false);
  },
};
</script>
